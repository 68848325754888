// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dta-ui-primary: mat.define-palette(mat.$indigo-palette);
$dta-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$dta-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$dta-ui-theme: mat.define-light-theme(
  (
    color: (
      primary: $dta-ui-primary,
      accent: $dta-ui-accent,
      warn: $dta-ui-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($dta-ui-theme);
@import "./palette.scss";
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-size: 18px;
}

* {
  font-family: "Encode Sans Expanded", sans-serif
}

.page {
  min-height: 85vh;
}

a {
  color: $accent !important;
}

.button:not(:disabled) {
  background-color: $accent !important;
  color: $form-background !important;
}

@media (min-width: 0px) {
  body {
    font-size: 10px !important;
  }
}

@media (min-width: 576px) {
  body {
    font-size: 12px !important;
  }
}

@media (min-width: 768px) {
  body {
    font-size: 14px !important;
  }
}

@media (min-width: 992px) {
  body {
    font-size: 16px !important;
  }
}

@media (min-width: 1200px) {
  body {
    font-size: 12px !important;
  }
}

@media (min-width: 1400px) {
  body {
    font-size: 14px !important;
  }
}

@media (min-width: 1920px) {
  body {
    font-size: 16px !important;
  }
}

@media (min-width: 2560px) {
  body {
    font-size: 18px !important;
  }
}

@media (min-width: 3840px) {
  body {
    font-size: 20px !important;
  }
}

.mat-paginator {
  background-color: $form-background;
  color: $font-color;
}
.mat-paginator-icon {
  fill: $accent;
}
